import React from 'react';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';

const Title = styled.h1`
  font-size: 40px;
`;

const Image = styled.img`
  max-height: 400px;
  display: block;
`;

const Copyright = styled.span`
  text-align: right;
  display: block;
  font-size: 13px;
  color: white;
  margin: 12px 0 32px;
`;

const Wrapper = styled.div`
  max-width: 720px;
  padding: 0 22px;
`;

const Subtitle = styled.h2`
  display: block;
  color: white;
  font-size: 30px;
  margin-top: 32px;
`;

const P = styled.p`
  color: white;
  font-size: 18px;
`;

function AalborgKarneval() {
  return (
    <>
      <SEO
        title="Det kan du opleve til Aalborg Karneval"
        description="Gå ikke glip af Aalborg Karneval. En oplevelse du aldrig vil glemme. Køb billetter til Aalborg Karneval her."
      />
      <Layout>
        <Wrapper className="event">
          <Title className="title">
            Det kan du opleve til Aalborg Karneval
          </Title>
          <Image
            className="event__image"
            src="https://www.aalborgkarneval.dk/wp-content/uploads/2018/12/Vesterbro.jpg"
            alt="Aalborg Karneval"
          />
          <Copyright>Billede fra aalborgkarneval.dk</Copyright>
          <Subtitle>
            <a
              href="https://www.billetlugen.dk/event/aalborg-karneval-2023-aalborg-16236734/?affiliate=MR4"
              style={{ borderBottom: '1px solid white' }}
            >
              Køb VIP billetter til Aalborg Karneval her
            </a>
          </Subtitle>
          <P className="event__info_explore">
            Aalborg Karneval er en af de største kulturelle begivenheder i
            Danmark, og tiltrækker hvert år hundredtusindvis af besøgende fra
            hele landet. Karnevalet i Aalborg er kendt for sin unikke blanding
            af farver, musik og dans, og det er en begivenhed, som folk ser frem
            til hvert år.
            <br />
            <br />
            Karnevalet i Aalborg blev første gang afholdt i 1983, og siden da er
            det vokset i popularitet og omfang. Karnevalet finder sted hvert år
            i slutningen af maj eller begyndelsen af juni.
            <br />
            <br />
            Karnevalet begynder normalt med en stor åbningsparade, hvor
            deltagerne går gennem byens gader og viser deres flotte og
            fantasifulde kostumer frem. Der er mange forskellige grupper, der
            deltager i paraden, herunder musikere, dansere, gymnaster,
            teatergrupper og meget mere. De fleste af deltagerne er amatører,
            men der er også professionelle grupper, der deltager i paraden.
            <br />
            <br />
            En af de ting, der gør Aalborg Karneval så unikt, er den store
            mængde af musik, der spilles under hele begivenheden. Der er
            musikalske optrædener fra forskellige bands og musikere på mange
            forskellige scener rundt omkring i byen. Musikken er en vigtig del
            af karnevalet, og det er svært ikke at blive fanget af den festlige
            stemning og danse til rytmerne.
            <br />
            <br />
            Et af højdepunkterne under karnevalet er den såkaldte "Nordic Samba
            Parade", hvor forskellige sambaskoler fra Danmark og andre nordiske
            lande går gennem byens gader og spiller samba-musik og danser i
            farverige kostumer. Paraden er en smuk og spektakulær oplevelse, og
            det er en god måde at opleve den brasilianske kultur på.
            <br />
            <br />
            En anden populær begivenhed under karnevalet er "The Battle of
            Carnival Bands", hvor forskellige bands dyster om at spille den
            bedste musik og skabe den bedste fest. Det er en sjov og energisk
            begivenhed, hvor publikum kan stemme på deres favoritband og danse
            med til musikken.
            <br />
            <br />
            Karnevalet i Aalborg er også kendt for at være en af de mest
            inkluderende og mangfoldige begivenheder i Danmark. Der er mange
            forskellige grupper og organisationer, der deltager i karnevalet,
            herunder LGBT+-organisationer, ungdomsgrupper, sportsklubber, skoler
            og meget mere. Dette afspejler den åbenhed og tolerance, som er en
            del af Aalborgs kultur
          </P>
        </Wrapper>
      </Layout>
    </>
  );
}

export default AalborgKarneval;
